import { Component, HostListener, ViewChild } from "@angular/core";
import { WindowHandlerService } from "./core/services";
import { ConfigService } from "./core/services/config.service";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { ROUTE_PATHS, API_METHOD } from "./core/constants";
import { IUserConfig } from "./core/models";
import { HTTP_STATUS_CODE } from "./core/constants/http-status.constant";
import { environment } from "../environments/environment";
import { Observable, fromEvent, merge, of } from "rxjs";
import { delay, mapTo } from "rxjs/operators";
import { Subscription } from "rxjs";
import { Location } from '@angular/common';
import { CookieService } from "ngx-cookie-service";
export let browserRefresh = false;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  isConfigLoad = true;
  subscription: Subscription;
  constructor(
    private location: Location,
    private readonly windowHandler: WindowHandlerService,
    private readonly configService: ConfigService,
    private readonly router: Router,
    private actRoute: ActivatedRoute,
    private cookieService: CookieService
  ) {
    // let session = localStorage.getItem("userConfigList");
    let session = this.cookieService.get("userConfigList");
    if (!session) {
      this.redirectToLogin();
    } else if (this.configService.currentUserConfig()) {
      this.handlingConfiguration(this.configService.currentUserConfig());
    }
    // var Url = `/${ROUTE_PATHS.easyview}/${this.companyId}/${ROUTE_PATHS.projects}/
    // ${ROUTE_PATHS.content}/${docDetails.DocID}/${docDetails.encDocID}?cname=${this.company.ClientName}`;
    // this.subscription = router.events.subscribe((event) => {
    //   if (event instanceof NavigationStart) {
    //     browserRefresh = !router.navigated;
    //   }
    // });
    // if (this.configService.currentUserConfig()) {
    // this.handlingConfiguration(this.configService.currentUserConfig());
    // }
    // this.configService.getUserConfiguration().subscribe(
    //   // (config) => {
    //   //   console.log("sssssss",config);
    //   //   this.handlingConfiguration(config);
    //   // },
    //   // (error) => {
    //   //   console.log("sssssss",error);
    //   //   // if (error.status === HTTP_STATUS_CODE.unAuthorized) {
    //   //      this.router.navigate(['/login']);
    //   //   // }
    //   // },
    // );
  }
  ngOnInit(): void {
    $(window).scroll(function () {
      const tableHeader = document.querySelector('table.table-sticky thead');
      if(!tableHeader) 
        return;
      else {
        const tableHeaderPosition = tableHeader.getBoundingClientRect();
        var $head = $('table.table-sticky thead');
        if (tableHeaderPosition.y  < 66) {
          $head.addClass('sticky');
        } else {
          $head.removeClass('sticky');
        }
      }
    });
  }
  
  getInformation(): any {
    let path = this.location.path();
    let split = path.split("?");
    if (split.length > 1) {
      let query = split[1].split("&");
      let queryParam = {};
      for (let i = 0; i < query.length; i++) {
        let param = query[i].split("=");
        queryParam[param[0]] = param[1];
      }
      return {
        url: {
          redirect: window.btoa(path),
        },
        path: split[0],
        params: queryParam,
      };
    } else {
      return {
        url: {
          redirect: window.btoa(path),
        },
        path: split[0],
        params: {},
      };
    }
  }

  redirectToLogin(): void {
    let info = this.getInformation();
    let fpath = info.path.split("/");
    console.log("fpath",fpath);

    let url = ROUTE_PATHS.xe_login;
    if (
      Object.keys(info.params).indexOf("cname") > -1 &&
      fpath.indexOf("easyview") > -1 &&
      fpath.indexOf("content") > -1  || fpath.indexOf("EA-Forms") > - 1
    ) {
      switch (info.params["cname"]) {
        case "cpu":
          url = ROUTE_PATHS.cpu_login;
          break;
        case "sfc":
          url = ROUTE_PATHS.securex_login;
          break;
        default:
          url = ROUTE_PATHS.xe_login;
          break;
      }
      this.router.navigate(["/" + url], { queryParams: info.url });
    }
  }

  private handlingConfiguration(config: IUserConfig) {
    let userId: any = localStorage.getItem('userDetailsData') || localStorage.getItem("userId") || localStorage.getItem("UserId");
    this.configService.setUserConfig(JSON.stringify(config));
    this.isConfigLoad = true;
    // this.configService.redirectToHome(config);
     let roles:string[] = config.UserRoles.map((list: any) => list.RoleName);
     let isReviewer:boolean = roles.includes("Reviewer") && roles.length === 1 ? true : false;
    // let isEaCsr: Boolean = roles.includes("EA-CSR") && roles.length == 1 ? true : false;
    // let url:string = !isReviewer ? `/${ROUTE_PATHS.DASHBOARD}` : `/${ROUTE_PATHS.easyview}/${ROUTE_PATHS.easyViewCompanies}`;
    // if(config.MODULE_ACCESS.length == 0 && userId != 1) {
    //   this.router.navigate([`/not-found`]);
    // }
     if (config.singleCompany && "CompanyID" in config.singleCompany) {
       if(isReviewer) {
        this.configService.isOnlyReviewer = true;
            }
 }
    // else if (config.singleCompany && "CompanyID" in config.singleCompany) {
    //   this.configService.isOnlyReviewer = true;
    //   url = `/${ROUTE_PATHS.dashboard} `;
    //   if(isReviewer) {
    //     url = config.MODULE_ACCESS ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found`;
    //   }
    //   else if(isEaCsr) {
    //       url =  config.MODULE_ACCESS ? `/${ROUTE_PATHS.DASHBOARD}` : `/not-found`;
       
    //   }

      // this.router.navigateByUrl(url);
      // this.router.navigateByUrl(
      //   `/${!isReviewer ? ROUTE_PATHS.COMPANY : ROUTE_PATHS.easyview}/${config.singleCompany.CompanyID}/${ROUTE_PATHS.DOCUMENTS}${isReviewer ? '?dtype=11': ''}`
      // );
    // }
   }

  // @HostListener('window:beforeunload', ['$event'])
  // unloadEventCatch(event) {
  //   if (this.windowHandler.isIMergixWindowActive()) {
  //     this.windowHandler.closeActiveImergixWindow();
  //   }
  // }
}
